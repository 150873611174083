import { css } from 'astroturf';
import React from 'react';
import Circle from '../../components/Circle';

const styles = css`
  .brands {
    position: relative;
    padding: 5rem 2rem 5rem;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    display: none;
    max-width: 82rem;

    @media (min-width: 600px) {
      display: block;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 1rem;
      margin-top: 0;
      max-width: 60rem;

      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      & > span > svg {
        height: 130%;
      }
      & > span > span {
        -webkit-text-fill-color: #d8ecf8;
      }
    }
    p {
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;
      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
    & .list {
      position: relative;
      margin: 1rem auto 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      margin-top: 4rem;
      gap: 2rem 3rem;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 20%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0) 100%
      );

      div {
        display: flex;
        flex: 1 1 12%;
        white-space: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #d8ecf8;
        line-height: 1.5;
        background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
        background-clip: text;
        background-size: 100% 1.5em;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-weight: bold;
        font-size: 1.5rem;

        svg {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }

  .graphicwrap {
    width: 120%;
    left: -10%;
    bottom: -15%;
    height: 700px;
    position: absolute;
    filter: blur(50px);
    z-index: -1;
    opacity: 0.25;
    overflow: hidden;
    max-width: 100vw;
  }
  .graphic {
    inset: 0;
    height: 700px;
    background: linear-gradient(90deg, #0a6a9e 0, rgba(103, 178, 111, 0.6) 100%);
    clip-path: polygon(
      100% 61.6%,
      97.5% 26.9%,
      85.5% 0.1%,
      74.1% 44.1%,
      80.7% 2%,
      72.5% 32.5%,
      52.4% 68.1%,
      60.2% 62.4%,
      47.5% 58.3%,
      27.5% 76.7%,
      0.1% 64.9%,
      45.2% 34.5%,
      17.9% 100%,
      27.6% 76.8%,
      76.1% 97.7%,
      74.1% 44.1%
    );
  }
`;

function Brands(props) {
  return (
    <div className={[styles.brands].join(' ')}>
      <h2>Portal keeps everything <Circle>in sync</Circle></h2>
      <p>
        Whatever you do in Polypane, happens in all connected browsers. No more one-by-one testing, no more manual
        refreshing.
      </p>
      <div className={styles.list}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-navigation"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 18.5l7.265 2.463c.196 .077 .42 .032 .57 -.116a.548 .548 0 0 0 .134 -.572l-7.969 -17.275l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463" />
          </svg>
          Navigation
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-reload"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
            <path d="M20 4v5h-5" />
          </svg>
          Reloads
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-mouse"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z" />
            <path d="M12 7l0 4" />
          </svg>
          Scroll
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-click"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12l3 0" />
            <path d="M12 3l0 3" />
            <path d="M7.8 7.8l-2.2 -2.2" />
            <path d="M16.2 7.8l2.2 -2.2" />
            <path d="M7.8 16.2l-2.2 2.2" />
            <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
          </svg>
          Clicks
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-forms"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3" />
            <path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3" />
            <path d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7" />
            <path d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1" />
            <path d="M17 12h.01" />
            <path d="M13 12h.01" />
          </svg>
          Form input
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
          Polypane Peek
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-pencil"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
            <path d="M13.5 6.5l4 4" />
          </svg>
          DOM Edits
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-grid-pattern"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
            <path d="M10 8v8" />
            <path d="M14 8v8" />
            <path d="M8 10h8" />
            <path d="M8 14h8" />
          </svg>
          Layout Debugging
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-file-type-css"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M8 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
            <path d="M11 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
            <path d="M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
          </svg>
          CSS Edits
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-bolt"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
          </svg>
          Live Reload
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-eye"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
          </svg>
          Element Visibility
        </div>
      </div>
      <div className={styles.graphicwrap}>
        <div className={styles.graphic}></div>
      </div>
    </div>
  );
}

export default Brands;
