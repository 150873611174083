import React, { useState } from 'react';
import { css } from 'astroturf';
import Punchout from '../../components/Punchout';
import portal from '../../../assets/videos/portal.mp4';

const styles = css`
  .box {
    gap: 1.5rem;
    padding: 0 1rem;
    width: 100%;
    color: rgba(200, 212, 234, 0.78);
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));
    margin: 4rem auto 4rem;
    max-width: 102rem;
    text-align: center;

    h2 {
      font-size: clamp(18px, 16px + 4vw, 44px);
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: -0.015em;
      margin: 0 0 1rem;

      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      margin-bottom: 4rem;
    }

    .videocontainer {
      display: grid;
      place-items: center;
      background: hsla(0, 0%, 100%, 0.56666666666666666);
      max-width: calc(100% - 2rem);
      margin: 0 auto 8rem;
      padding: 0.6rem;
      border-radius: 1rem;
      box-shadow:
        rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.7) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;

      &::after {
        grid-area: 1 / 1;
        z-index: 2;
        content: '▶';
        place-items: center;
        display: block;
        left: calc(50% - 75px);
        top: calc(50% - 75px);
        font-size: 100px;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
        color: #000;
        pointer-events: none;
        opacity: 0;
        transition: 0.2s ease-in-out;
        border-radius: 9999px;
        background: hsla(0, 0%, 100%, 0.56666666666666666);
        padding-left: 8px;
        box-shadow:
          rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.7) 0px 30px 60px -30px,
          rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;
        transform: translateZ(100px);
      }

      &.paused::after {
        opacity: 0.95;
        transition: 0.1s ease-in-out;
      }
    }

    video {
      grid-area: 1 / 1;
      display: block;
      max-width: 100%;
      border-radius: 0.5rem;
      box-shadow:
        rgba(255, 255, 255, 0.6) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.7) 0px 5px 10px;
    }
  }
`;

// eslint-disable-next-line arrow-body-style
const Video = () => {
  const [playing, setPlaying] = useState(false);

  const video = React.useRef(null);

  const handleClick = () => {
    if (video.current.paused) {
      video.current.play();
      setPlaying(true);
    } else {
      video.current.pause();
      setPlaying(false);
    }
  };

  return (
    <div className={styles.box}>
      <h2>See it in action</h2>
      <p>Portal is a joy to use with any browser, device simulator or real device.</p>
      <div className={[styles.videocontainer, playing ? styles.playing : styles.paused].join(' ')}>
        <video ref={video} onClick={handleClick} src={portal} muted loop preload="true" loading="lazy" playsInline />
      </div>
    </div>
  );
};

export default Video;
