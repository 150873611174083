import { css } from 'astroturf';
import React, { useState, useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../../components/SEO';
import Layout from '../../layout/index';

import CTA from '../../components/CTA';
import Punchout from '../../components/Punchout';
import init from './_canvas';
import URLBar from './_URLBar';
import Bento from './_bento';
import Brands from './_brands';
import Crossplatform from './_crossplatform';
import Feedback from './_feedback';
import Testimonials from './_testimonials';
import Sync from './_sync';
import Platforms from './_platforms';
import Video from './_video';

const styles = css`
  .bgwrap {
    position: absolute;
    inset: 0;
    height: 100vh;
    z-index: 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40vh;
      content: '';
      background: linear-gradient(180deg, rgba(9, 20, 26, 0) 0%, #09141a 100%);
    }
    > div {
      width: 100%;
    }
  }
  .bg {
    z-index: 0;
    position: absolute !important;
    inset: -20% 0 0;
    height: 120vh;
  }

  .canvas {
    position: absolute;
    height: 100vh;
    z-index: 0;
    opacity: 0.7;
    pointer-events: none;
  }

  .pageHeader {
    position: relative;
    display: grid;
    text-align: center;
    padding: 0 1rem;

    div.content {
      margin-top: 16vh;
      padding-bottom: 200px;
      z-index: 1;

      & > span {
        display: flex;
        filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 600;
        gap: 1rem;

        &::before {
          content: ' ';
          display: block;
          height: 1px;
          width: 80px;
          background: linear-gradient(90deg, #d8ecf800 0, #d8ecf8 100%);
        }
        &::after {
          content: ' ';
          display: block;
          height: 1px;
          width: 80px;
          background: linear-gradient(-90deg, #d8ecf800 0, #d8ecf8 100%);
        }
      }

      h1 {
        margin: 0;
        line-height: 0.8;
        color: transparent;
        font-size: calc(1rem + 14vw);
        font-weight: 700;
        color: #d8ecf8;
        background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
        background-clip: text;
        text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8)) brightness(1.33);
        mask-image: linear-gradient(180deg, #000 60%, transparent 95%);
      }

      & > p {
        font-size: clamp(1rem, 0.75rem + 1vw, 1.5rem);
        font-weight: 600;
        line-height: 1.5;
        margin: -2vh auto 0;
        max-width: min(770px, calc(90vh - 2rem));
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
      }
    }
  }

  .gradientText {
    color: #d8ecf8;
    background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
    background-clip: text;
    background-size: 100% 1.5em;
    text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container {
    padding: 0 2rem;
    position: relative;
    color: rgba(220, 232, 254, 0.9);
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));

    h2 {
      font-size: clamp(18px, 16px + 4vw, 44px);
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: -0.015em;
      margin: 0 0 0.5rem;
    }

    p {
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
  }
`;

const Page = (props) => {
  const canvas = useRef(null);
  const [canvasInitialized, setCanvasInitialized] = useState(false);

  useEffect(() => {
    const noReduce = window.matchMedia('(prefers-reduced-motion: no-preference)').matches;

    if (!canvasInitialized && noReduce) {
      init(canvas.current, { maxRadius: 0.5, vy: 0.025, vx: 0.025, shootingStarInterval: 500 });
      setCanvasInitialized(true);
    }
  });

  const setPortalAnim = (active) => {
    if (canvasInitialized) {
      canvas.current.classList.toggle('portal', active);
    }
  };

  return (
    <Layout background="#09141a" darkHeader>
      <SEO
        title="Polypane Portal"
        description="Polypane Portal shares your local development environment with anyone, anywhere, on any device while keeping everything in sync."
        keywords={['']}
        pathname={props.location.pathname}
        ogFileName="portal"
        ogCustomPath="/og-custom/"
      />
      <div className={styles.bgwrap}>
        <StaticImage src="../../../assets/images/portal/bg.png" alt="" className={styles.bg} />
        <canvas ref={canvas} className={styles.canvas} />
      </div>

      <PageHeader nobg overflow>
        <div className={styles.content}>
          <span className={styles.gradientText}>Introducing</span>
          <h1>Portal</h1>
          <p className={styles.gradientText}>
            Share your local development environment anywhere, on any browser, on any device. Test and inspect directly
            from Polypane.
          </p>

          <URLBar setPortalAnim={setPortalAnim} />
        </div>
      </PageHeader>

      <div className={styles.container}>
        <h2>
          <span className={styles.gradientText}>Everything that makes Polypane great.</span>
          <br />
          <span className={styles.gradientText}>
            Available in <Punchout>all browsers</Punchout>.
          </span>
        </h2>
        <p>
          Make your local URLs available across your network or on a secret public URL. Polypane Portal keeps everything
          in sync: the scroll position, interactions, style edits and layout debugging.
        </p>
        <p>
          <b>Included with Polypane</b>.
        </p>
      </div>
      <Bento />
      <Brands />
      <Video />
      <Crossplatform />
      <Sync />
      <Feedback />
      <Testimonials />
      <Platforms />
    </Layout>
  );
};

export default Page;

const PageHeader = ({ children, nobg, overflow }) => {
  return <header className={[styles.pageHeader].join(' ')}>{children}</header>;
};
