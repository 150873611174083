function Starfield(config) {
  config = config || {};

  this.canvas = typeof config.canvas === 'string' ? document.querySelector(config.canvas) : config.canvas;
  this.ctx = this.canvas.getContext('2d');
  this.style = getComputedStyle(this.canvas);

  this.vx = config.vx || 0.05;
  this.vy = config.vy || 0.05;

  this.maxStars = parseInt(this.style.width.replace('px', '') * 0.5, 10);
  this.maxRadius = config.maxRadius || 1.5;

  this.shootingStarInterval = config.shootingStarInterval;
  this.lastShootingStar = this.shootingStarInterval ? performance.now() : undefined;
  this.shootingStar;

  this.onResize();

  window.addEventListener('resize', this.onResize.bind(this));
}

Starfield.prototype.star = function star() {
  const colors = ['255,255,255', '255,255,255', '255,255,255', '255,255,200', '200,200,255', '255,200,200'];
  return {
    x: Math.round(Math.random() * this.canvas.width),
    y: Math.round(Math.random() * this.canvas.height),
    r: 0.5 + Math.random() * this.maxRadius,
    l: Math.random(),
    bl: 0.1 * (Math.random() * 6 + 2),
    dl: Math.round(Math.random()) === 1 ? 0.01 : -0.01,
    c: colors[Math.floor(Math.random() * colors.length)],
    s: Math.random() * 0.05,
  };
};

Starfield.prototype.loadStars = function loadStars() {
  this.stars = new Array(this.numStars);

  let i = this.numStars;
  while (i-- > 0) this.stars[i] = this.star();
};

Starfield.prototype.onResize = function onResize() {
  const ratio = window.devicePixelRatio || 1;

  this.canvas.width = this.style.width.replace('px', '') | (0 * ratio);
  this.canvas.height = this.style.height.replace('px', '') | (0 * ratio);

  this.canvas.center = {
    x: this.canvas.width / 2.1,
    y: this.canvas.height / 2.9,
  };

  this.numStars = parseInt(this.canvas.width * 0.5, 10);

  this.loadStars();
};

Starfield.prototype.draw = function draw(star) {
  this.ctx.beginPath();
  this.ctx.fillStyle = `rgba(${star.c},${star.l})`;
  this.ctx.arc(star.x, star.y, star.r, 0, 2 * Math.PI, false);
  this.ctx.fill();
  this.ctx.shadowBlur = star.r * 2;
  this.ctx.shadowColor = 'white'; // "rgba(" + star.c + ",1)";
};

Starfield.prototype.start = function start() {
  const tick = function onTick(timeStamp) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let i = this.stars.length;
    while (i-- > 0) {
      const star = this.stars[i];

      this.draw(star);

      if (this.canvas.classList.contains('portal')) {
        const dx = star.x - this.canvas.center.x;
        const dy = star.y - this.canvas.center.y;
        star.angle = Math.atan2(dy, dx);

        star.speed = star.speed || Math.random() * 50; // Random speed
        star.x += Math.cos(star.angle) * (star.s * star.speed + this.vy);
        star.y += Math.sin(star.angle) * (star.s * star.speed + this.vx);

        star.l += star.dl;
        if (Math.abs(star.l - star.bl) >= 0.25) star.dl = -star.dl;

        if (star.x < 0 || star.x > this.canvas.width || star.y < 0 || star.y > this.canvas.height) {
          // Reset star to near the center
          star.x = Math.random() * 400 - 200 + this.canvas.center.x;
          star.y = Math.random() * 400 - 200 + this.canvas.center.y;
          star.r = 1 + Math.random() * this.maxRadius;
        }
        // multiple draws to create a streak
        this.draw(star);
        star.x += Math.cos(star.angle) * (star.s * star.speed + this.vy);
        star.y += Math.sin(star.angle) * (star.s * star.speed + this.vx);
        this.draw(star);
        star.x += Math.cos(star.angle) * (star.s * star.speed + this.vy);
        star.y += Math.sin(star.angle) * (star.s * star.speed + this.vx);
        this.draw(star);
        star.x += Math.cos(star.angle) * (star.s * star.speed + this.vy);
        star.y += Math.sin(star.angle) * (star.s * star.speed + this.vx);
        this.draw(star);
      } else {
        star.y += star.s + this.vy;
        star.x += star.s + this.vx;
        star.l += star.dl;

        if (Math.abs(star.l - star.bl) >= 0.25) star.dl = -star.dl;

        if (star.x < -1 * star.r) {
          star.x = this.canvas.width + star.r;
        } else if (star.x > this.canvas.width) {
          star.x = -1 * star.r;
        }

        if (star.y < -1) {
          star.y = this.canvas.height + star.r;
        } else if (star.y > this.canvas.height + star.r) {
          star.y = -1;
        }
      }
    }

    if (this.shootingStar) {
      var star = this.shootingStar;

      this.draw(star);

      star.y += 3;
      star.x += 7;
      star.l += star.dl;
      star.r -= 0.05;

      if (star.r <= 0) this.shootingStar = undefined;
    } else if (this.shootingStarInterval) {
      if (timeStamp - this.lastShootingStar >= this.shootingStarInterval) {
        this.shootingStar = this.star();
        this.lastShootingStar = timeStamp;
        this.shootingStar.r = 4;
      }
    }

    this.frameId = window.requestAnimationFrame(tick);
  }.bind(this);

  this.frameId = window.requestAnimationFrame(tick);
};

export default function init(canvas, settings) {
  new Starfield({
    canvas,
    ...settings,
  }).start();
}
