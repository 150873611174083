import React, { useState } from 'react';
import { css } from 'astroturf';
import Punchout from '../../components/Punchout';
import { StaticImage } from 'gatsby-plugin-image';

const styles = css`
  .box {
    display: flex;
    gap: 1.5rem;
    padding: 0 1rem;
    width: 100%;
    color: rgba(200, 212, 234, 0.78);
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));
    margin: 4rem auto 8rem;
    max-width: 82rem;
    align-items: center;

    @media (max-width: 60rem) {
      flex-direction: column;

      .graphic {
        width: 100%;
      }
    }
  }

  .container {
    flex: 1 1 45%;
    padding: 0 2rem;
    position: relative;
    color: rgba(220, 232, 254, 0.9);
    max-width: 960px;
    margin: 0 auto;
    text-align: left;
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));

    h2 {
      font-size: clamp(18px, 16px + 4vw, 44px);
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: -0.015em;
      margin: 0 0 1rem;
    }

    p {
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
    p + p {
      margin-top: 1.6rem;
    }
  }
  .graphic {
    flex: 1 1 45%;
    display: grid;
    place-items: center;
    align-content: center;
    gap: 0rem;
    border-radius:4rem;
    padding:2rem 0;

    mask-image: radial-gradient(ellipse at center, #000 40%, transparent 95%);
    background-image: radial-gradient(#208dc8 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;

    svg {
      filter: drop-shadow(0 2px 16px rgba(174, 207, 242, 0.24));
      stroke: #d8ecf8;
      --size: 5rem;
      height: var(--size);
      width: var(--size);
      margin-left: calc(var(--size) * -2);

      &:nth-child(2) {
        margin-left: calc(var(--size) * 2);
      }
      &:nth-child(3) {
        margin-left: calc(var(--size) * -1);
      }
    }
  }
`;

// eslint-disable-next-line arrow-body-style
const Bento = () => {
  return (
    <div className={styles.box}>
      <div className={styles.graphic}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-screen-share"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M21 12v3a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h9" />
          <path d="M7 20l10 0" />
          <path d="M9 16l0 4" />
          <path d="M15 16l0 4" />
          <path d="M17 4h4v4" />
          <path d="M16 9l5 -5" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-messages"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
          <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-thumb-up"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
        </svg>
      </div>
      <div className={styles.container}>
        <h2>
          <Punchout>Click. Share.</Punchout>
          <br />
          <span className={styles.gradientText}>Instant Feedback.</span>
        </h2>
        <p>
          Share a live preview with anyone and collaborate. Any changes you make in devtools are instantly visible on
          all connected devices and browsers.
        </p>
      </div>
    </div>
  );
};

export default Bento;
