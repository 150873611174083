import React, { useState } from 'react';
import { css } from 'astroturf';

import { StaticImage } from 'gatsby-plugin-image';

const styles = css`
  .bento {
    max-width: 82rem;
    text-align: left;
    margin: 4rem auto 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 1.5rem;
    padding: 0 1rem;
    width: 100%;
    color: rgba(200, 212, 234, 0.78);
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));

    @media (max-width: 50rem) {
      grid-template-columns: repeat(1, 1fr);
    }

    & > div {
      transition: 0.15s ease-in-out box-shadow;
      &:hover {
        box-shadow: 0 2px 26px rgba(174, 207, 242, 0.24);
      }
    }
  }

  .boxwide {
    grid-column: 1/3;
  }
  .box {
    grid-column: 3 / 4;
  }

  .boxwide2 {
    grid-column: 2/4;
  }
  .box2 {
    grid-column: 1 / 2;
  }

  @media (max-width: 50rem) {
    .boxwide,
    .box,
    .boxwide2,
    .box2 {
      grid-column: 1 / 2;
    }
  }

  .inner {
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    outline: 2px solid hsl(224deg 12% 40% / 0.5);
    outline-offset: -2px;
    justify-content: end;
    border-radius: 0.5rem;
    background: #1e1b26;
    width: auto;
    height: 250px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    transition: 0.15s ease-in-out outline;

    &:hover {
      outline: 2px solid hsl(224deg 12% 60% / 0.5);

      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
    }

    & :global(.gatsby-image-wrapper) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      mask-image: linear-gradient(25deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 80%);
    }

    &.rotated :global(.gatsby-image-wrapper) {
      mask-image: linear-gradient(35deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 80%);
    }
    &.leftAlign {
      & :global(.gatsby-image-wrapper) {
        left: 0;
        right: auto;

        & img {
          object-position: 0 0;
        }
      }
    }
    &.fullAlign {
      & :global(.gatsby-image-wrapper) {
        left: 0;
        right: 0;

        & img {
          object-position: 0 0;
        }
      }
    }

    h3 {
      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
    p {
      max-width: 40ch;
      text-wrap: balance;
    }
  }

  .gradientText {
    color: #d8ecf8;
    background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
    background-clip: text;
    background-size: 100% 1.5em;
    text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .graphicwrap {
    width: 120%;
    left: -10%;
    bottom: -15%;
    height: 700px;
    position: absolute;
    filter: blur(50px);
    z-index: -1;
    opacity: 0.25;
    overflow: hidden;
    max-width: 100vw;
  }
  .graphic {
    inset: 0;
    height: 700px;
    background: linear-gradient(0deg, #0a6a9e 0, #98c0ef 100%);
    clip-path: polygon(
      100% 61.6%,
      97.5% 26.9%,
      85.5% 0.1%,
      74.1% 44.1%,
      80.7% 2%,
      72.5% 32.5%,
      52.4% 68.1%,
      60.2% 62.4%,
      47.5% 58.3%,
      27.5% 76.7%,
      0.1% 64.9%,
      45.2% 34.5%,
      17.9% 100%,
      27.6% 76.8%,
      76.1% 97.7%,
      74.1% 44.1%
    );
  }
`;

// eslint-disable-next-line arrow-body-style
const Bento = () => {
  return (
    <div className={styles.bento}>
      <div className={styles.boxwide}>
        <div className={styles.inner}>
          <StaticImage src="../../../assets/images/portal/browsers.png" alt="" />
          <h3>All browsers invited</h3>
          <p>From Safari on your latest iPhone to Firefox on your old Kindle Fire.</p>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.inner}>
          <StaticImage src="../../../assets/images/portal/sync.png" alt="" />
          <h3>Synced Interactions</h3>
          <p>Clicks, scrolling, typing and navigation are all kept in sync across all devices.</p>
        </div>
      </div>

      <div className={styles.box2}>
        <div className={[styles.inner, styles.leftAlign].join(' ')}>
          <StaticImage src="../../../assets/images/portal/layout.png" alt="" />
          <h3>Layout Debugging</h3>
          <p>Fix layout bugs across all connected browsers in seconds.</p>
        </div>
      </div>
      <div className={styles.boxwide2}>
        <div className={[styles.inner, styles.rotated].join(' ')}>
          <StaticImage src="../../../assets/images/portal/inspect.png" alt="" />
          <h3>Cross-browser editing</h3>
          <p>Use the Polypane Elements Panel to inspect and edit across browsers.</p>
        </div>
      </div>
      <div className={styles.boxwide}>
        <div className={[styles.inner, styles.rotated].join(' ')}>
          <StaticImage src="../../../assets/images/portal/preview.png" alt="" />
          <h3>Preview and Collaborate</h3>
          <p>Share a live preview with anyone and collaborate in real-time. They see what you see.</p>
        </div>
      </div>
      <div className={styles.box}>
        <div className={[styles.inner, styles.fullAlign].join(' ')}>
          <StaticImage src="../../../assets/images/portal/reload.png" alt="" />
          <h3>Live reloading and CSS injection</h3>
          <p>Automatically inject CSS and images on file changes.</p>
        </div>
      </div>
      <div className={styles.graphicwrap}>
        <div className={styles.graphic}></div>
      </div>
    </div>
  );
};

export default Bento;
