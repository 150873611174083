import React, { useState } from 'react';
import { css } from 'astroturf';
import Punchout from '../../components/Punchout';
import { StaticImage } from 'gatsby-plugin-image';

const styles = css`
  .box {
    display: flex;
    gap: 1.5rem;
    padding: 0 1rem;
    width: 100%;
    color: rgba(200, 212, 234, 0.78);
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));
    margin: 4rem auto 4rem;
    max-width: 82rem;
    align-items: center;

    @media (max-width: 60rem) {
      flex-direction: column;
    }
  }

  .container {
    flex: 1 1 45%;
    padding: 0 2rem;
    position: relative;
    color: rgba(220, 232, 254, 0.9);
    max-width: 960px;
    margin: 0 auto;
    text-align: left;
    filter: drop-shadow(0 0 3px rgba(100, 0, 80, 0.8));

    h2 {
      font-size: clamp(18px, 16px + 4vw, 44px);
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: -0.015em;
      margin: 0 0 1rem;
    }

    p {
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
    p + p {
      margin-top: 1.6rem;
    }
  }
  .graphic {
    flex: 1 1 45%;
    mask-image: linear-gradient(180deg, #000 60%, transparent 95%);
  }
`;

// eslint-disable-next-line arrow-body-style
const Bento = () => {
  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <h2>
          <span className={styles.gradientText}>Cross-browser and Cross-device testing.</span>
          <br /> <Punchout>Made extremely easy.</Punchout>
        </h2>
        <p>
          Test many rendering engines and devices in one go. Everything you do in Polypane is automatically mirrored
          across all connected devices and browsers.
        </p>
      </div>
      <div className={styles.graphic}>
        <StaticImage src="../../../assets/images/portal/browsers2.png" alt="Cross-platform testing" />
      </div>
    </div>
  );
};

export default Bento;
